// Función para generar un rango de meses entre dos fechas
export function generateMonthRange(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const months = [];

  const date = new Date(startDate);
  while (date <= endDate) {
    const month = date.toISOString().slice(0, 7);
    months.push(month);
    date.setMonth(date.getMonth() + 1);
  }

  return months;
}

// Función para normalizar los datos de ingresos o gastos
export function normalizeData(data, monthRange) {
  const dataMap = new Map(data.map(([month, value]) => [month, value]));
  return monthRange.map((month) => dataMap.get(month) || 0);
}

export function cumulativeSum(arr) {
  let cumulativeArray = [];
  arr.reduce((accumulator, currentValue, index) => {
    cumulativeArray[index] = accumulator + currentValue;
    return cumulativeArray[index];
  }, 0);
  return cumulativeArray;
}

export function calculateNet(income, expenses) {
  // Asegúrate de que ambos arrays tienen la misma longitud
  if (income.length !== expenses.length) {
    throw new Error(
      "Los arrays de ingresos y gastos deben tener la misma longitud."
    );
  }

  let netIncome = [];

  for (let i = 0; i < income.length; i++) {
    netIncome.push(income[i] - expenses[i]);
  }

  return netIncome;
}

export function calculateAverage(arr) {
  if (arr.length === 0) {
    return 0; // Avoid division by zero
  }

  const sum = arr.reduce((acc, value) => acc + value, 0);
  const average = sum / arr.length;

  return average;
}

export function getCumulativeArray(values) {
  const cumulativeArray = [];

  let cumulativeSum = 0;

  values.forEach((value) => {
    cumulativeSum += value;

    cumulativeArray.push(cumulativeSum);
  });

  return cumulativeArray;
}

export function calculateAnnualizedAmount(data) {
  // Paso 1: Sumar todos los amounts
  const totalSum = data.reduce((accumulator, currentValue) => {
    return accumulator + currentValue[1];
  }, 0);

  // Paso 2: Obtener los meses únicos presentes en los datos
  const uniqueMonths = new Set(data.map((item) => item[0].substring(0, 7)))
    .size;

  // Paso 3: Calcular el promedio mensual
  const averageMonthly = totalSum / uniqueMonths;

  // Paso 4: Calcular la cantidad anualizada
  const annualizedAmount = averageMonthly * 12;

  return annualizedAmount;
}

export function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
