//export const backendUrl = "http://localhost:8001";
export const backendUrl = "https://api.puzzle-housing.com";

// Rutas estáticas
export const nonAuthUrls = [
  "/users/",
  "/token/",
  "/activation/",
  "/users/reset_password/",
  "/custom-reset-password/",
  "/incoming/",
  "/next-property/",
  "/all-active-properties/",
  "/pzl-dashboard/",
  "/base-contracts/",
  "/protocol-stats/",
  "/dividend-drops/",
];

// Expresión regular para la ruta dinámica
const dynamicUrlPattern = /^\/property\/\d+\/$/;
const dynamicUrlPatternBills = /^\/property-bills\/\d+\/$/;

// Función para verificar si una URL no requiere autenticación
export const nonRequiresAuth = (url) => {
  // Verifica las rutas estáticas
  if (nonAuthUrls.includes(url)) {
    return true;
  }

  // Verifica la ruta dinámica
  if (dynamicUrlPattern.test(url) || dynamicUrlPatternBills.test(url)) {
    return true;
  }

  return false;
};
