import ERC20 from "./abiContracts/ERC20.json";
import PZL from "./abiContracts/PuzzleFactory.json";
import Property from "./abiContracts/PuzzleProperty.json";
import PRM from "./abiContracts/PuzzleShares.json";

const addressDai = "0xFF34B3d4Aee8ddCd6F9AFFFB6Fe49bD371b8a357";
const contractDaiAbi = ERC20.abi;
const DECIMALS_FUNDING_TOKEN = 10 ** 18;

const contractPZLAbi = PZL.abi;
const contractPRMAbi = PRM.abi;

const contractPropertyAbi = Property.abi;

const alchemyEndPoint =
  "https://eth-sepolia.g.alchemy.com/v2/b99KC6iCJL02GcBHlFLzGnmzIzS-335w";

const explorer = "https://sepolia.etherscan.io/tx/";

export {
  addressDai,
  contractDaiAbi,
  contractPZLAbi,
  contractPropertyAbi,
  alchemyEndPoint,
  DECIMALS_FUNDING_TOKEN,
  explorer,
  contractPRMAbi,
};
