const footerMenuList = [
  {
    id: 1,
    title: "Explore",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/collection/explore_collection",
        text: "Explore All Properties",
      },
      {
        id: 2,
        href: "/help_center/get_started",
        text: "Add Tokens to MetaMask",
      },
    ],
  },
  {
    id: 2,
    title: "Resorces",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/help_center",
        text: "Help Center",
      },
      {
        id: 2,
        href: "/pools",
        text: "Liquidity Pools",
      },
      {
        id: 3,
        href: "/maintenance",
        text: "Leverage Properties",
      },
      {
        id: 4,
        href: "/blog",
        text: "Blog",
      },
      {
        id: 5,
        href: "/puzzleHousingICO",
        text: "PuzzleHouse ICO",
      },
      {
        id: 6,
        href: "/contact",
        text: "Contact",
      },
    ],
  },
  {
    id: 3,
    title: "Stats",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/vault",
        text: "PZL Stats",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://twitter.com/iNcAeLuM007",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://www.discord.com",
    text: "discord",
  },
  {
    id: 4,
    href: "https://www.instagram.com",
    text: "instagram",
  },
  {
    id: 5,
    href: "https://www.tiktok.com",
    text: "tiktok",
  },
];

export { footerMenuList, socialIcons };
