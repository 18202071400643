const saveTokens = (accessToken, refreshToken) => {
  try {
    localStorage.setItem("access", accessToken);
    localStorage.setItem("refresh", refreshToken);
  } catch (error) {
    console.error("Error al guardar tokens:", error);
  }
};

const getTokens = () => {
  try {
    const access = localStorage.getItem("access");
    const refresh = localStorage.getItem("refresh");
    return { access, refresh };
  } catch (error) {
    console.error("Error al recuperar tokens:", error);
    return null;
  }
};

export { saveTokens, getTokens };
