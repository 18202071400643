import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leverageInfoModal: false,
  PZLBalance: 0,
  buyPZLModal: false,
  buyPRMModal: false,
  updateNav: "",
  invoiceMenu: "menu",
  invoiceModal: false,
  invoiceSelected: "",
  netWorth: [],
  addressTokenA: "",
  addressTokenB: "",
  currentPairAddress: "",
  removeLiquidityModal: false,
  allOWNMinted: 0,
  idProperties: [],
  balanceOWNOfId: 0,
  propertyContractAddress: "",
  allowanceOfId: {},
  allMyPropertiesCount: 0,
  propertiesArray: [],
  propertiesArraywithDividends: [],
  totalOWN: 0,
  buyGUTModal: false,
  gutUnclaimedDividends: 0,
  gutAnnualizedROI: 0,
  lastDividendDate: 0,
  checkRewards: 0,
  claimRewards: [],
  allProperties: [],
  refundModal: false,
  daiAllowed: 0,
  daiBalance: 0,
  currentId: 0,
  buyOWNModal: false,
  gutTotalSupply: 0,
  gutBalance: 0,
  mblMenu: false,
  dropdown: false,
  collection_activity_item_data: [],
  trendingCategoryItemData: [],
  sortedtrendingCategoryItemData: [],
  collectiondata: [],
  sortedCollectionData: [],
  renkingData: [],
  filteredRenkingData: [],
  walletModal: false,
  buyModal: false,
  propartiesModalValue: false,
  trendingCategorySorText: "",
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    leverageInfoModalShow: (state) => {
      state.leverageInfoModal = true;
    },
    leverageInfoModalHide: (state) => {
      state.leverageInfoModal = false;
    },
    setPZLBalance: (state, action) => {
      state.PZLBalance = action.payload;
    },
    buyPZLModalShow: (state) => {
      state.buyPZLModal = true;
    },
    buyPZLModalHide: (state) => {
      state.buyPZLModal = false;
    },
    buyPRMModalShow: (state) => {
      state.buyPRMModal = true;
    },
    buyPRMModalHide: (state) => {
      state.buyPRMModal = false;
    },
    setUpdateNav: (state, action) => {
      state.updateNav = action.payload;
    },
    setInvoiceMenu: (state, action) => {
      state.invoiceMenu = action.payload;
    },
    setInvoiceSelected: (state, action) => {
      state.invoiceSelected = action.payload;
    },
    invoiceModalShow: (state) => {
      state.invoiceModal = true;
    },
    invoiceModalHide: (state) => {
      state.invoiceModal = false;
    },
    setNetWorth: (state, action) => {
      state.netWorth = action.payload;
    },
    setAddressTokenA: (state, action) => {
      state.addressTokenA = action.payload;
    },
    setAddressTokenB: (state, action) => {
      state.addressTokenB = action.payload;
    },
    setCurrentPairAddress: (state, action) => {
      state.currentPairAddress = action.payload;
    },
    removeLiquidityModalShow: (state) => {
      state.removeLiquidityModal = true;
    },
    removeLiquidityModalHide: (state) => {
      state.removeLiquidityModal = false;
    },
    setAllOWNMinted: (state, action) => {
      state.allOWNMinted = action.payload;
    },
    setPropertiesArraywithDividends: (state, action) => {
      state.propertiesArraywithDividends = action.payload;
    },
    setIdProperties: (state, action) => {
      state.idProperties = action.payload;
    },
    setBalanceOWNOfId: (state, action) => {
      state.balanceOWNOfId = action.payload;
    },
    setPropertyContractAddress: (state, action) => {
      state.propertyContractAddress = action.payload;
    },
    setAllowanceOfId: (state, action) => {
      state.allowanceOfId = action.payload;
    },
    setAllMyPropertiesCount: (state, action) => {
      state.allMyPropertiesCount = action.payload;
    },
    setPropertiesArray: (state, action) => {
      state.propertiesArray = action.payload;
    },
    setTotalOWN: (state, action) => {
      state.totalOWN = action.payload;
    },
    buyGUTModalShow: (state) => {
      state.buyGUTModal = true;
    },
    buyGUTModalHide: (state) => {
      state.buyGUTModal = false;
    },
    setGutUnclaimedDividends: (state, action) => {
      state.gutUnclaimedDividends = action.payload;
    },
    setGutAnnualizedROI: (state, action) => {
      state.gutAnnualizedROI = action.payload;
    },
    setLastDividendDate: (state, action) => {
      state.lastDividendDate = action.payload;
    },
    setCheckRewards: (state, action) => {
      state.checkRewards = action.payload;
    },
    setClaimRewards: (state, action) => {
      state.claimRewards = action.payload;
    },
    setAllproperties: (state, action) => {
      state.allProperties = action.payload;
    },
    refundModalHide: (state) => {
      state.refundModal = false;
    },
    refundModalShow: (state) => {
      state.refundModal = true;
    },
    setDaiAllowed: (state, action) => {
      state.daiAllowed = action.payload;
    },

    setDaiBalance: (state, action) => {
      state.daiBalance = action.payload;
    },
    setCurrentId: (state, action) => {
      state.currentId = action.payload;
    },

    stakeModalHide: (state) => {
      state.stakeModal = false;
    },
    stakeModalShow: (state) => {
      state.stakeModal = true;
    },

    setGutTotalSupply: (state, action) => {
      state.gutTotalSupply = action.payload;
    },
    setGutBalance: (state, action) => {
      state.gutBalance = action.payload;
    },

    openMblMenu: (state) => {
      state.mblMenu = true;
    },
    closeMblMenu: (state) => {
      state.mblMenu = false;
    },

    openDropdown: (state) => {
      state.dropdown = true;
    },
    closeDropdown: (state) => {
      state.dropdown = false;
    },
    handle_collection_activity_item_data: (state, payload) => {
      state.collection_activity_item_data = payload.data;
    },
    walletModalShow: (state) => {
      state.walletModal = true;
    },
    walletModalhide: (state) => {
      state.walletModal = false;
    },
    buyOWNModalShow: (state) => {
      state.buyOWNModal = true;
    },
    buyOWNModalHide: (state) => {
      state.buyOWNModal = false;
    },
    stackModalShow: (state) => {
      state.stackModal = true;
    },
    stackModalHide: (state) => {
      state.stackModal = false;
    },
    buyModalShow: (state) => {
      state.buyModal = true;
    },
    buyModalHide: (state) => {
      state.buyModal = false;
    },
    showPropatiesModal: (state) => {
      state.propartiesModalValue = true;
    },
    closePropatiesModal: (state) => {
      state.propartiesModalValue = false;
    },
    updateTrendingCategoryItemData: (state, action) => {
      state.trendingCategoryItemData = action.payload;
      state.sortedtrendingCategoryItemData = action.payload;
    },
    updatetrendingCategorySorText: (state, action) => {
      const sortText = action.payload;
      if (sortText === "Price: Low to High") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort(
            (a, b) => a.sortPrice - b.sortPrice
          );
      } else if (sortText === "Price: high to low") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort(
            (a, b) => b.sortPrice - a.sortPrice
          );
      } else if (sortText === "Recently Added") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort((a, b) => a.addDate - b.addDate);
      } else if (sortText === "Auction Ending Soon") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.sort((a, b) => b.addDate - a.addDate);
      } else {
        state.sortedtrendingCategoryItemData = state.trendingCategoryItemData;
      }
    },
    updateTrendingCategoryItemByInput: (state, action) => {
      const text = action.payload;
      if (text === "Verified Only") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.verified;
          });
      } else if (text === "NFSW Only") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.nfsw;
          });
      } else if (text === "Show Lazy Minted") {
        state.sortedtrendingCategoryItemData =
          state.trendingCategoryItemData.filter((item) => {
            return item.lazyMinted;
          });
      } else {
        state.sortedtrendingCategoryItemData = state.trendingCategoryItemData;
      }
    },
    collectCollectionData: (state, action) => {
      const data = action.payload;
      state.collectiondata = data;
      state.sortedCollectionData = data;
    },
    updateCollectionData: (state, action) => {
      const text = action.payload;
      console.log(text);
      if (text === "trending") {
        const tampItem = state.collectiondata.filter((item) => item.trending);
        state.sortedCollectionData = tampItem;
      }
      if (text === "top") {
        const tampItem = state.collectiondata.filter((item) => item.top);
        state.sortedCollectionData = tampItem;
      }
      if (text === "recent") {
        const tampItem = state.collectiondata.filter((item) => item.recent);
        state.sortedCollectionData = tampItem;
      }
      // state.sortedCollectionData = state.collectiondata;
    },
    collectRenkingData: (state, action) => {
      state.renkingData = action.payload;
      state.filteredRenkingData = action.payload;
    },
    updateRenkingData: (state, action) => {
      const text = action.payload;
      let tempItem = state.renkingData.filter((item) => item.category === text);
      if (text === "All") {
        tempItem = state.renkingData;
      }
      state.filteredRenkingData = tempItem;
    },
    updateRenkingDataByBlockchain: (state, action) => {
      const text = action.payload;
      let tempItem = state.renkingData.filter(
        (item) => item.blockchain === text
      );
      if (text === "All") {
        tempItem = state.renkingData;
      }
      state.filteredRenkingData = tempItem;
    },
    updateRenkingDataByPostdate: (state, action) => {
      const text = action.payload;
      let tempItem = state.renkingData.filter((item) => item.postDate === text);
      if (text === "All Time" || text === "Last Year") {
        tempItem = state.renkingData;
      }
      state.filteredRenkingData = tempItem;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  leverageInfoModalShow,
  leverageInfoModalHide,
  setPZLBalance,
  buyPZLModalShow,
  buyPZLModalHide,
  buyPRMModalShow,
  buyPRMModalHide,
  updateNav,
  setInvoiceMenu,
  setInvoiceSelected,
  invoiceModalShow,
  invoiceModalHide,
  setNetWorth,
  setAddressTokenA,
  setAddressTokenB,
  setCurrentPairAddress,
  removeLiquidityModalShow,
  removeLiquidityModalHide,
  setPropertiesArraywithDividends,
  setIdProperties,
  setBalanceOWNOfId,
  setPropertyContractAddress,
  setAllowanceOfId,
  setAllMyPropertiesCount,
  setPropertiesArray,
  setTotalOWN,
  setAllOWNMinted,
  buyGUTModalShow,
  buyGUTModalHide,
  setGutUnclaimedDividends,
  setGutAnnualizedROI,
  setLastDividendDate,
  setCheckRewards,
  setClaimRewards,
  setAllproperties,
  refundModalHide,
  refundModalShow,
  setDaiAllowed,
  setDaiBalance,
  setCurrentId,
  setGutTotalSupply,
  setGutBalance,
  openMblMenu,
  closeMblMenu,
  openDropdown,
  closeDropdown,
  walletModalShow,
  walletModalhide,
  buyOWNModalHide,
  buyOWNModalShow,
  buyModalShow,
  buyModalHide,
  showPropatiesModal,
  closePropatiesModal,
  updatetrendingCategorySorText,
  updateTrendingCategoryItemData,
  updateTrendingCategoryItemByInput,
  collectCollectionData,
  updateCollectionData,
  collectRenkingData,
  updateRenkingData,
  updateRenkingDataByBlockchain,
  updateRenkingDataByPostdate,
} = counterSlice.actions;

export default counterSlice.reducer;
