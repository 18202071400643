import React from "react";
import { useMetaMask } from "metamask-react";
import { walletModalShow } from "../../redux/counterSlice";
import Image from "next/image";
import { ethers } from "ethers";
import {
  addressDai,
  contractDaiAbi,
  contractPZLAbi,
  contractPropertyAbi,
  contractPRMAbi,
} from "../../data/contractConstants";
import {
  addressUniswapFacotry,
  contractAbiUniswapFactory,
  interfaceUniswapPairAbi,
  interfaceUniswapRouterAbi,
  addressUniswapRouter,
} from "../../data/interfacesConstants";
import transformArray from "../transformArray";
import { DECIMALS_FUNDING_TOKEN } from "../../data/contractConstants";

const SECONDS_IN_A_MONTH = 3600 * 24 * 30;
const RAY = 10 ** 27;
const SECONDS_PER_YEAR = 31536000;

const providerInfura =
  "https://sepolia.infura.io/v3/7dc291f13d6c40fc8ce7ddef3a325142";

const providerAlchemy =
  "https://eth-sepolia.g.alchemy.com/v2/b99KC6iCJL02GcBHlFLzGnmzIzS-335w";

const providerEtherscan = "CIM4VNTJCFXW7SYAZW3TC42TSQW9KI3BRB";

const providerInstanceContract = (address, abi, providerUrl) => {
  const provider = new ethers.providers.JsonRpcProvider(providerUrl);
  const contract = new ethers.Contract(address, abi, provider);
  return contract;
};

const metamaskInstanceContract = (address, abi) => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(address, abi, signer);

  return contract;
};

const getERC20Balance = async (addressERC20, account) => {
  const contract = metamaskInstanceContract(addressERC20, contractDaiAbi);
  const balance = await contract.balanceOf(account);

  return balance;
};

const getERC20Allowance = async (addressERC20, owner, spender) => {
  const contract = metamaskInstanceContract(addressERC20, contractDaiAbi);
  const balance = await contract.allowance(owner, spender);

  return balance;
};

const getTokenName = async (address) => {
  const contract = metamaskInstanceContract(address, contractDaiAbi);
  const tokenName = await contract.symbol();
  return tokenName;
};

const getFundedAmountProperty = async (propertyAddress) => {
  const contract = providerInstanceContract(
    propertyAddress,
    contractPropertyAbi,
    providerInfura
  );
  const fundedAmount = await contract.fundedAmount();

  return fundedAmount;
};

const getFundedAmountPropertyMetamask = async (propertyAddress) => {
  const contract = metamaskInstanceContract(
    propertyAddress,
    contractPropertyAbi
  );
  const fundedAmount = await contract.fundedAmount();

  return fundedAmount;
};

const getAvaliableToClaimPZLMetamask = async (propertyAddress, account) => {
  const contract = metamaskInstanceContract(
    propertyAddress,
    contractPropertyAbi
  );
  const avaliablePZL = await contract.allowanceMintPZL(account);

  return avaliablePZL;
};

const getReserves = async (pairAddress) => {
  const contract = providerInstanceContract(
    pairAddress,
    interfaceUniswapPairAbi,
    providerInfura
  );

  const reserves = await contract.getReserves();

  return reserves;
};

const approveERC20 = async (erc20Address, spender, amount) => {
  const contract = metamaskInstanceContract(erc20Address, contractDaiAbi);

  const approve_tx = await contract.approve(
    spender,
    BigInt(amount * DECIMALS_FUNDING_TOKEN)
  );

  return approve_tx;
};

const fundAsset = async (propertyAddress, amount) => {
  const contract = metamaskInstanceContract(
    propertyAddress,
    contractPropertyAbi
  );

  const fund_tx = await contract.fund(BigInt(amount * DECIMALS_FUNDING_TOKEN));

  return fund_tx;
};

const buyPRM = async (addressPRM, account, amount, idNumber) => {
  const contract = metamaskInstanceContract(addressPRM, contractPRMAbi);

  const mint_tx = await contract.mint(
    account,
    BigInt(amount * 10 ** 18),
    idNumber
  );

  return mint_tx;
};

const getAvalaiblePZL = async (propertyAddress, account) => {
  const contract = metamaskInstanceContract(
    propertyAddress,
    contractPropertyAbi
  );

  const avaliablePZL = await contract.allowanceMintPZL(account);

  return avaliablePZL;
};

const claimPzl = async (propertyAddress) => {
  const contract = metamaskInstanceContract(
    propertyAddress,
    contractPropertyAbi
  );
  const claim_tx = await contract.claimAssets();

  return claim_tx;
};

const claimDividends = async (pzlAddress) => {
  const contract = metamaskInstanceContract(pzlAddress, contractPZLAbi);
  const claim_tx = await contract.claimRewards();

  return claim_tx;
};

const getAvaliableDividends = async (pzlAddress, account) => {
  const contract = metamaskInstanceContract(pzlAddress, contractPZLAbi);

  const avaliableToClaim = await contract.checkDividends(account);

  return avaliableToClaim;
};

const getRefundStatus = async (propertyAddress) => {
  const contract = providerInstanceContract(
    propertyAddress,
    contractPropertyAbi,
    providerInfura
  );
  const refundStatus = await contract.checkRefund();

  return refundStatus;
};

const refundAssets = async (propertyAddress) => {
  const contract = metamaskInstanceContract(
    propertyAddress,
    contractPropertyAbi
  );

  const refund_tx = await contract.refundAssets();

  return refund_tx;
};

const plzTotalSupply = async (plzAddress) => {
  const contract = providerInstanceContract(
    plzAddress,
    contractPZLAbi,
    providerInfura
  );

  const totalSupply = await contract.totalSupply();

  return totalSupply;
};

const swap = async (amountIn, amountOutMin, path, accountTo) => {
  const contract = metamaskInstanceContract(
    addressUniswapRouter,
    interfaceUniswapRouterAbi
  );
  const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
  const swapAssets = await contract.swapExactTokensForTokens(
    BigInt(amountIn * 10 ** 18),
    BigInt(amountOutMin * 10 ** 18),
    path,
    accountTo,
    deadline
  );

  return swapAssets;
};

const getRoundData = async (prmAddress, idNumber) => {
  const contract = providerInstanceContract(
    prmAddress,
    contractPRMAbi,
    providerInfura
  );
  const roundData = await contract.roundData(idNumber);

  return roundData;
};

const Metamask_comp_text = () => {
  const dispatch = useDispatch();

  const { status, connect, account, chainId, ethereum } = useMetaMask();

  if (status === "initializing")
    return (
      <div className="js-wallet bg-accent shadow-accent-volume hover:bg-accent-dark block w-full rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
        Synchronisation with MetaMask ongoing...
      </div>
    );

  if (status === "unavailable")
    return (
      <div className="js-wallet bg-accent shadow-accent-volume hover:bg-accent-dark block w-full rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
        MetaMask not available :
      </div>
    );

  if (status === "notConnected")
    return (
      <button
        className="js-wallet bg-accent shadow-accent-volume hover:bg-accent-dark block w-full rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
        onClick={connect}
      >
        Connect Wallet
      </button>
    );

  if (status === "connecting")
    return (
      <div className="js-wallet bg-accent shadow-accent-volume hover:bg-accent-dark block w-full rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
        Connecting...
      </div>
    );

  if (status === "connected")
    return (
      <div>
        <button
          className="js-wallet bg-accent shadow-accent-volume hover:bg-accent-dark block w-full rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
          onClick={() => dispatch(walletModalShow())}
        >
          Connect Wallet {account}
        </button>
      </div>
    );
};

const Metamask_comp_login = () => {
  const { status, connect, account, chainId, ethereum } = useMetaMask();

  if (status === "initializing")
    return (
      <button className="js-wallet bg-accent hover:bg-accent-dark mb-4 flex w-full items-center justify-center rounded-full border-2 border-transparent py-4 px-8 text-center font-semibold text-white transition-all">
        <Image
          src="/images/wallets/metamask_24.svg"
          className="mr-2.5 inline-block h-6 w-6"
          alt=""
          height={24}
          width={24}
        />
        <span className="ml-2.5">Metamask initializing</span>
      </button>
    );

  if (status === "unavailable")
    return (
      <button className="js-wallet bg-accent hover:bg-accent-dark mb-4 flex w-full items-center justify-center rounded-full border-2 border-transparent py-4 px-8 text-center font-semibold text-white transition-all">
        <Image
          src="/images/wallets/metamask_24.svg"
          className="mr-2.5 inline-block h-6 w-6"
          alt=""
          height={24}
          width={24}
        />
        <span className="ml-2.5">unavailable</span>
      </button>
    );

  if (status === "notConnected")
    return (
      <button
        className="js-wallet bg-accent hover:bg-accent-dark mb-4 flex w-full items-center justify-center rounded-full border-2 border-transparent py-4 px-8 text-center font-semibold text-white transition-all"
        onClick={connect}
      >
        <Image
          src="/images/wallets/metamask_24.svg"
          className="inline-block h-6 w-6"
          alt=""
          height={24}
          width={24}
        />
        <span className="ml-2.5">Sign in with Metamask</span>
      </button>
    );

  if (status === "connecting")
    return (
      <button className="js-wallet bg-accent hover:bg-accent-dark mb-4 flex w-full items-center justify-center rounded-full border-2 border-transparent py-4 px-8 text-center font-semibold text-white transition-all">
        <Image
          src="/images/wallets/metamask_24.svg"
          className="mr-2.5 inline-block h-6 w-6"
          alt=""
          height={24}
          width={24}
        />
        <span className="ml-2.5">Metamask connecting</span>
      </button>
    );

  if (status === "connected")
    return (
      <button className="js-wallet bg-accent hover:bg-accent-dark mb-4 flex w-full items-center justify-center rounded-full border-2 border-transparent py-4 px-8 text-center font-semibold text-white transition-all">
        <Image
          src="/images/wallets/metamask_24.svg"
          className=" inline-block h-6 w-6"
          alt=""
          height={24}
          width={24}
        />
        <span className="ml-2.5">Sign in with Metamask</span>
      </button>
    );
};

const Metamask_comp_icon = ({ prop }) => {
  const dispatch = useDispatch();
  const { status, connect, account, chainId, ethereum } = useMetaMask();

  if (status === "initializing")
    return (
      <div>
        <button
          className={
            prop.asPath === "/home/home_3"
              ? "js-wallet border-jacarta-100  focus:bg-accent group hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent border-transparent bg-white/[.15]"
              : "js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          }
          // onClick={() => dispatch(walletModalShow())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className={
              prop.asPath === "/home/home_3"
                ? " h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white fill-white"
                : "fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            }
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z"></path>
          </svg>
        </button>
      </div>
    );

  if (status === "unavailable")
    return (
      <div>
        <button
          className={
            prop.asPath === "/home/home_3"
              ? "js-wallet border-jacarta-100  focus:bg-accent group hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent border-transparent bg-white/[.15]"
              : "js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          }
          // onClick={() => dispatch(walletModalShow())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className={
              prop.asPath === "/home/home_3"
                ? " h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white fill-white"
                : "fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            }
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z"></path>
          </svg>
        </button>
      </div>
    );

  if (status === "notConnected")
    return (
      <button
        className={
          prop.asPath === "/home/home_3"
            ? "js-wallet border-jacarta-100  focus:bg-accent group hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent border-transparent bg-white/[.15]"
            : "js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
        }
        // onClick={() => dispatch(walletModalShow())}
        onClick={connect}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className={
            prop.asPath === "/home/home_3"
              ? " h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white fill-white"
              : "fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
          }
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z"></path>
        </svg>
      </button>
    );

  if (status === "connecting")
    return (
      <div>
        <button
          className={
            prop.asPath === "/home/home_3"
              ? "js-wallet border-jacarta-100  focus:bg-accent group hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent border-transparent bg-white/[.15]"
              : "js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          }
          // onClick={() => dispatch(walletModalShow())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className={
              prop.asPath === "/home/home_3"
                ? " h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white fill-white"
                : "fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            }
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z"></path>
          </svg>
        </button>
      </div>
    );

  if (status === "connected")
    return (
      <div>
        <button
          className={
            prop.asPath === "/home/home_3"
              ? "js-wallet border-jacarta-100  focus:bg-accent group hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent border-transparent bg-white/[.15]"
              : "js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          }
          onClick={() => dispatch(walletModalShow())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className={
              prop.asPath === "/home/home_3"
                ? " h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white fill-white"
                : "fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            }
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z"></path>
          </svg>
        </button>
      </div>
    );
};

export {
  Metamask_comp_text,
  Metamask_comp_icon,
  Metamask_comp_login,
  getERC20Balance,
  getERC20Allowance,
  getTokenName,
  getFundedAmountProperty,
  getFundedAmountPropertyMetamask,
  getAvaliableToClaimPZLMetamask,
  approveERC20,
  getReserves,
  fundAsset,
  claimPzl,
  getAvalaiblePZL,
  claimDividends,
  getAvaliableDividends,
  getRefundStatus,
  refundAssets,
  plzTotalSupply,
  swap,
  getRoundData,
  buyPRM,
};
