import { apiSlice } from "../services/apiSlice";

const mainApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    retrieveNewProperties: builder.query({
      query: () => ({
        url: "/next-property/",
        method: "GET",
      }),
    }),
    retrieveAllActiveProperties: builder.query({
      query: () => ({
        url: "/all-active-properties/",
        method: "GET",
      }),
    }),
    retrieveProperty: builder.query({
      query: (id) => ({
        url: `/property/${id}/`,
        method: "GET",
      }),
    }),
    retrievePropertyPlusBills: builder.query({
      query: (id) => ({
        url: `/property-bills/${id}/`,
        method: "GET",
      }),
    }),
    getDataPzlDashboard: builder.query({
      query: () => ({
        url: `/pzl-dashboard/`,
        method: "GET",
      }),
    }),
    getBaseContracts: builder.query({
      query: () => ({
        url: `/base-contracts/`,
        method: "GET",
      }),
    }),
    getProtocolStats: builder.query({
      query: () => ({
        url: `/protocol-stats/`,
        method: "GET",
      }),
    }),
    getDividendDrops: builder.query({
      query: () => ({
        url: `/dividend-drops/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyRetrieveNewPropertiesQuery,
  useLazyRetrieveAllActivePropertiesQuery,
  useLazyRetrievePropertyQuery,
  useLazyRetrievePropertyPlusBillsQuery,
  useLazyGetDataPzlDashboardQuery,
  useLazyGetBaseContractsQuery,
  useLazyGetProtocolStatsQuery,
  useLazyGetDividendDropsQuery,
} = mainApiSlice;
