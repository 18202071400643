import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./services/apiSlice";
import counterSlice from "./counterSlice";

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  counter: counterSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
