const items_data = [
  // bids data

  {
    id: "item_0",
    images: [
      "/images/products/item_0/item_0_0.jpg",
      "/images/products/item_0/item_0_1.jpg",
      "/images/products/item_0/item_0_2.jpg",
      "/images/products/item_0/item_0_3.jpg",
      "/images/products/item_0/item_0_4.jpg",
      "/images/products/item_0/item_0_5.jpg",
      "/images/products/item_0/item_0_6.jpg",
      "/images/products/item_0/item_0_7.jpg",
      "/images/products/item_0/item_0_8.jpg",
      "/images/products/item_0/item_0_9.jpg",
      "/images/products/item_0/item_0_10.jpg",
      "/images/products/item_0/item_0_11.jpg",
      "/images/products/item_0/item_0_12.jpg",
      "/images/products/item_0/item_0_13.jpg",
      "/images/products/item_0/item_0_14.jpg",
      "/images/products/item_0/item_0_15.jpg",
    ],
    title: "Altea (Alicante), Spain",
    area: 66,
    rooms: 2,
    zip: 28080,
    street: "street Alancat",
    state: "Alicante",
    country: "Spain",
    googleCoordinates: "29.9791163,31.1324434",
    price: 220000,
    funded: 35000,
    avgAreaRent: 700,
    elevator: true,
    garage: true,
    added: "10-May-2023",
    text: "Just a few meters from the apartment, you will find the pleasant seafront promenade of Altea la Olla, where you can discover restaurants, beach bars, and sports ports such as El Portet or Portosenso, where you can enjoy various activities.The apartment offers various cost-effective renovation possibilities, such as creating an open-concept loft to gain more space in the apartment.",
  },
  {
    id: "item_1",
    images: [
      "/images/products/item_1/item_1_0.jpg",
      "/images/products/item_1/item_1_1.jpg",
      "/images/products/item_1/item_1_2.jpg",
      "/images/products/item_1/item_1_3.jpg",
      "/images/products/item_1/item_1_4.jpg",
      "/images/products/item_1/item_1_5.jpg",
      "/images/products/item_1/item_1_6.jpg",
      "/images/products/item_1/item_1_7.jpg",
      "/images/products/item_1/item_1_8.jpg",
      "/images/products/item_1/item_1_9.jpg",
      "/images/products/item_1/item_1_10.jpg",
      "/images/products/item_1/item_1_11.jpg",
      "/images/products/item_1/item_1_12.jpg",
      "/images/products/item_1/item_1_13.jpg",
      "/images/products/item_1/item_1_14.jpg",
    ],
    title: "Malaga, Spain",
    area: 65,
    rooms: 2,
    zip: 29001,
    street: "Arquitecto González Edo",
    state: "Malaga",
    country: "Spain",
    googleCoordinates: "36.7217274,-4.4303223",
    price: 225000,
    avgAreaRent: 300,
    elevator: true,
    garage: false,
    added: "5-Sep-2023",
    text: "The apartment is located a 5-minute walk from the historic center, so you'll have all the city's gems and tourist attractions within easy reach, such as the Cathedral, the Thyssen Museum, the Picasso Museum, the Cervantes Theater, the Roman Theater, and more. However, at the same time, the location is very convenient: close to all kinds of services (supermarkets, health center, pharmacy, gym, etc.).",
  },
  {
    id: "item_2",
    images: [
      "/images/products/item_2/item_2_0.jpg",
      "/images/products/item_2/item_2_1.jpg",
      "/images/products/item_2/item_2_2.jpg",
      "/images/products/item_2/item_2_3.jpg",
      "/images/products/item_2/item_2_4.jpg",
      "/images/products/item_2/item_2_5.jpg",
      "/images/products/item_2/item_2_6.jpg",
      "/images/products/item_2/item_2_7.jpg",
      "/images/products/item_2/item_2_8.jpg",
      "/images/products/item_2/item_2_9.jpg",
      "/images/products/item_2/item_2_10.jpg",
      "/images/products/item_2/item_2_11.jpg",
      "/images/products/item_2/item_2_12.jpg",
      "/images/products/item_2/item_2_13.jpg",
      "/images/products/item_2/item_2_14.jpg",
      "/images/products/item_2/item_2_15.jpg",
      "/images/products/item_2/item_2_16.jpg",
    ],
    title: "Sevilla, Spain",
    area: 71,
    rooms: 2,
    zip: 28080,
    street: "street María Josefa Segovia",
    state: "Sevilla",
    country: "Spain",
    googleCoordinates: "37.386813,-5.9675727",
    price: 220000,
    avgAreaRent: 800,
    elevator: true,
    added: "5-Sep-2023",
    text: "One of the most exclusive areas in the city, it boasts a privileged location that will allow you to be close to everything you need, such as Los Arcos Shopping Center, Nervión Plaza Shopping Center, and Ramón Sánchez-Pizjuán Stadium. Additionally, it is close to supermarkets, restaurants, schools, bus stops, and an 11-minute walk from Sevilla Sta. Justa train station.",
  },
  {
    id: "item_3",
    images: [
      "/images/products/item_3/item_3_0.jpg",
      "/images/products/item_3/item_3_1.jpg",
      "/images/products/item_3/item_3_2.jpg",
      "/images/products/item_3/item_3_3.jpg",
      "/images/products/item_3/item_3_4.jpg",
      "/images/products/item_3/item_3_5.jpg",
      "/images/products/item_3/item_3_6.jpg",
      "/images/products/item_3/item_3_7.jpg",
      "/images/products/item_3/item_3_8.jpg",
      "/images/products/item_3/item_3_9.jpg",
      "/images/products/item_3/item_3_10.jpg",
      "/images/products/item_3/item_3_11.jpg",
      "/images/products/item_3/item_3_12.jpg",
      "/images/products/item_3/item_3_13.jpg",
      "/images/products/item_3/item_3_14.jpg",
      "/images/products/item_3/item_3_15.jpg",
      "/images/products/item_3/item_3_16.jpg",
      "/images/products/item_3/item_3_17.jpg",
    ],
    title: "Malaga, Spain",
    area: 89,
    rooms: 3,
    zip: 29001,
    street: "Victoria Eugenia",
    state: "Malaga",
    country: "Spain",
    googleCoordinates: "36.7281729,-4.4381849",
    price: 185000,
    avgAreaRent: 1500,
    elevator: true,
    added: "5-Sep-2023",
    text: "This place features 3 bedrooms, 1 full bathroom with a shower, a well-lit living room, intact interior doors, a secure exterior door, a fully furnished open kitchen, well-kept communal areas, and an elevator in the building.",
  },
];

const new_property = {
  images: [
    "/images/products/next/next_0.jpg",
    "/images/products/next/next_1.jpg",
    "/images/products/next/next_2.jpg",
    "/images/products/next/next_3.jpg",
    "/images/products/next/next_4.jpg",
    "/images/products/next/next_5.jpg",
    "/images/products/next/next_6.jpg",
    "/images/products/next/next_7.jpg",
    "/images/products/next/next_8.jpg",
    "/images/products/next/next_9.jpg",
    "/images/products/next/next_10.jpg",
    "/images/products/next/next_11.jpg",
    "/images/products/next/next_12.jpg",
    "/images/products/next/next_13.jpg",
    "/images/products/next/next_14.jpg",
    "/images/products/next/next_15.jpg",
    "/images/products/next/next_16.jpg",
    "/images/products/next/next_17.jpg",
  ],
  title: "Malaga, Spain",
  area: 104,
  rooms: 3,
  zip: 29001,
  street: "Aparejo",
  state: "Malaga",
  country: "Spain",
  googleCoordinates: "36.7213874,-4.4433258",
  price: 230000,
  avgAreaRent: "$2000~$3000",
  elevator: true,
  added: "05-Sep-2023",
  startsIn: 3600000 * 24 * 7 + 1699885369975,
  text: "The property consists of 3 bedrooms with built-in closets, one of them with an en-suite bathroom, a living-dining room, a fully equipped separate kitchen, and 2 bathrooms. The house also has air conditioning. This property has an excellent location as it is just a few minutes walk from Aparejador Federico Bermúdez Square and Campo Malaga. It is also surrounded by supermarkets, restaurants, etc.",
};

export { items_data, new_property };
