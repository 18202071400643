import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import RefundModal from "./modal/RefundModal";

import Header01 from "./header/Header01";

export default function Layout({ children }) {
  return (
    <>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block dark:hidden h-full">
        <img
          src="/images/gradient.jpg"
          alt="gradient"
          className="h-full w-full"
        />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <img
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full"
        />
      </picture>
      <Header01 />
      <Wallet_modal />

      <main>{children}</main>
      <Footer />
    </>
  );
}
