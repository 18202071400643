import React, { useState, useEffect } from "react";
import { items_data } from "../../data/items_data";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

function SearchForm() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const router = useRouter();

  useEffect(() => {
    setQuery("");
    setResults([]);
  }, [router.pathname]);

  const searchItems = () => {
    const filteredResults = items_data.filter((item) => {
      return (
        item.title.toLowerCase().includes(query.toLowerCase()) ||
        item.state.toLowerCase().includes(query.toLowerCase())
      );
    });
    setResults(filteredResults);
  };

  return (
    <div className="relative">
      <form
        action="search"
        className="relative ml-12 mr-8 lg:block basis-3/12 xl:ml-[8%]"
        onSubmit={(e) => {
          e.preventDefault();
          searchItems();
        }}
      >
        <input
          type="search"
          className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
          placeholder="Search Property"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            searchItems(); // Realiza la búsqueda en tiempo real
          }}
        />
        <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="fill-jacarta-500 h-4 w-4 dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
          </svg>
        </span>
      </form>
      <div
        className={`${
          results.length > 0
            ? "absolute top-11 left-0 z-10 rounded-2xl mt-3 ml-5 dark:bg-jacarta-500 bg-jacarta-100 py-3 px-5 font-display w-full"
            : "hidden"
        } ${query.length == 0 && "hidden"}`}
      >
        {results.map((result) => (
          <ul key={result.id}>
            <li className="mt-2">
              <Link href={`/item/${result.id}`}>
                <div className="flex hover:text-accent">
                  <div>
                    <Image
                      className="cursor-pointer"
                      src={result.images[0]}
                      width={30}
                      height={30}
                      alt="result_image"
                    />
                  </div>
                  <div className="cursor-pointer hover:text-accent ml-3 text-jacarta-600 dark:text-white">
                    <span className="hover:text-accent">
                      id #{result.id[result.id.length - 1]} &nbsp;&nbsp;
                      {result.title}
                    </span>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        ))}
      </div>
    </div>
  );
}

export default SearchForm;
