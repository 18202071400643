import IPoolAddressesProvider from "./interfaces/IPoolAddressesProvider.json";
import IPool from "./interfaces/IPool.json";
import IUniswapV2Factory from "./interfaces/IUniswapV2Factory.json";
import IUniswapV2Pair from "./interfaces/IUniswapV2Pair.json";
import IUniswapV2Router from "./interfaces/IUniswapV2Router.json";

const addressPoolProvider = "0x0496275d34753A48320CA58103d5220d394FF77F";
const interfaceAbiPoolProvider = IPoolAddressesProvider.abi;

const interfacePool = IPool.abi;

const addressUniswapFacotry = "0x7E0987E5b3a30e3f2828572Bb659A548460a3003";
const contractAbiUniswapFactory = IUniswapV2Factory.abi;

const interfaceUniswapPairAbi = IUniswapV2Pair.abi;

const addressUniswapRouter = "0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008";
const interfaceUniswapRouterAbi = IUniswapV2Router.abi;

export {
  addressPoolProvider,
  interfaceAbiPoolProvider,
  interfacePool,
  addressUniswapFacotry,
  contractAbiUniswapFactory,
  interfaceUniswapPairAbi,
  addressUniswapRouter,
  interfaceUniswapRouterAbi,
};
