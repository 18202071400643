import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setIsAuthenticated } from "../counterSlice";
import { Mutex } from "async-mutex";
import { getTokens, saveTokens } from "../../secureStore/authService";
import { backendUrl, nonRequiresAuth } from "../backendConfig";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: `${backendUrl}/api`,
  credentials: "include",
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let tokens = getTokens();

  let result;

  if (nonRequiresAuth(args.url)) {
    result = await baseQuery({ ...args }, api, extraOptions);
  } else {
    result = await baseQuery(
      { ...args, headers: { Authorization: `Bearer ${tokens.access}` } },
      api,
      extraOptions
    );
  }

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: "/token/refresh/",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: {
              refresh: tokens.refresh,
            },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          tokens = {
            access: refreshResult.data.access,
            refresh: tokens.refresh,
          };
          saveTokens(tokens);
          api.dispatch(setIsAuthenticated(true));
          result = await baseQuery(
            { ...args, headers: { Authorization: `Bearer ${tokens.access}` } },
            api,
            extraOptions
          );
        } else {
          api.dispatch(setIsAuthenticated(false));
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(
        { ...args, headers: { Authorization: `Bearer ${tokens.access}` } },
        api,
        extraOptions
      );
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
