import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { refundModalHide } from "../../redux/counterSlice";
import {
  refundAssets,
  getRefundStatus,
  getAvalaiblePZL,
} from "../metamask/Metamask";
import { useMetaMask } from "metamask-react";
import Link from "next/link";
import Loading from "../Loading";
import { formatNumber } from "../../utils/invoiceDataNormalizer";

const RefundModal = ({ property }) => {
  const { status, connect, account, chainId, ethereum } = useMetaMask();
  const [isProcessing, setIsProcessing] = useState(false);
  const [tx, setTx] = useState(0);
  const [refundStatus, setRefundStatus] = useState([]);
  const [avaliableToRefund, setAvaliableToRefund] = useState(0);

  const { refundModal, allowanceOfId, currentId, idProperties } = useSelector(
    (state) => state.counter
  );
  const dispatch = useDispatch();

  const canRefund = refundStatus[1] && !refundStatus[0];

  const handleRefundAssetsClick = () => {
    refundAssets(property.contract)
      .then((response) => {
        setIsProcessing(true);
        setTx(response.hash);
        return response.wait();
      })
      .then(() => {
        getAvalaiblePZL(property.contract, account).then((response) =>
          setAvaliableToRefund(response)
        );
      })
      .finally(() => setIsProcessing(false));
  };

  useEffect(() => {
    getRefundStatus(property.contract).then((response) =>
      setRefundStatus(response)
    );
    if (account) {
      getAvalaiblePZL(property.contract, account).then((response) =>
        setAvaliableToRefund(response)
      );
    }
  }, [account]);

  useEffect(
    () => setTx(0),

    [refundModal]
  );

  return (
    <div>
      <div className={refundModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title" id="placeBidLabel">
                <div className="flex">
                  <h2>Refund Assets</h2>{" "}
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => dispatch(refundModalHide())}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                    </svg>
                  </button>
                </div>

                <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
                  You are about to refund your Assets from:{" "}
                  <span className="text-base ml-3">id #{property.id}</span>{" "}
                </span>
              </div>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              <div className="mb-2 font-display">
                <span>Requirements to refund:</span>
              </div>
              {refundStatus.length > 0 && (
                <ul className="font-display text-jacarta-500 dark:text-jacarta-200 text-sm list-disc ml-6 mb-6">
                  <li>
                    Funding Time limit expired:{" "}
                    <span
                      className={`ml-2 ${
                        refundStatus[0] ? "text-green" : "text-red"
                      }`}
                    >
                      {refundStatus[0] ? "Pass" : "No pass"}
                    </span>{" "}
                  </li>
                  <li>
                    Funded Porpery not at 100%:{" "}
                    <span
                      className={`ml-2 ${
                        !refundStatus[1] ? "text-green" : "text-red"
                      }`}
                    >
                      {!refundStatus[1] ? "Pass" : "No Pass"}
                    </span>{" "}
                  </li>
                </ul>
              )}

              <div className="dark:border-jacarta-600 border-jacarta-100 relative mb-2 flex items-center overflow-hidden rounded-lg border"></div>
              <div className="text-center">
                <span className="dark:text-jacarta-200 text-jacarta-500 font-display text-sm">
                  <div>
                    Avaliable To Refund:{" "}
                    <span className="text-blue">
                      {formatNumber(
                        parseFloat(avaliableToRefund / 10 ** 18).toFixed(2)
                      )}{" "}
                      PZL
                    </span>{" "}
                  </div>
                </span>
              </div>
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                {canRefund ? (
                  <button
                    onClick={handleRefundAssetsClick}
                    type="button"
                    className={`flex w-32 justify-center dark:border-jacarta-600 border-jacarta-200 rounded-lg border py-3  mx-4  bg-accent  cursor-pointer hover:bg-jacarta-300 dark:hover:bg-jacarta-800  text-sm `}
                  >
                    <span className="text-white font-bold">
                      {isProcessing ? <Loading size={6} /> : "Refund Assets"}
                    </span>
                  </button>
                ) : (
                  <button
                    disabled
                    type="button"
                    className={`flex w-32 justify-center dark:border-jacarta-600 border-jacarta-200 rounded-lg border py-3 opacity-50  mx-4  bg-accent     text-sm `}
                  >
                    <span className="text-white font-bold">Refund Assets</span>
                  </button>
                )}
              </div>
            </div>
            {tx != 0 && (
              <div className="modal-footer">
                <div className="flex items-center justify-center space-x-4 ">
                  Transaction Hash:{" "}
                  <Link href={`https://sepolia.etherscan.io/tx/${tx}`}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cursor-pointer hover:text-accent ml-3"
                    >
                      {`${tx.slice(0, 10)}...${tx.slice(-10)}`}
                    </a>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundModal;
